@import 'styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: left;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 590px;
  margin-top: 40px;
  transition: all 300ms ease;
  @media (max-width: $md) {
    height: 214px;
  }
}

.bgColor {
  width: 100%;
  height: 100%;
  background: $font-color;
}

.cover {
  position: absolute;
  bottom: 20px;
  padding: 0 60px;
  color: $secondary-light;

  @media (max-width: $md) {
    padding: 0 16px;
  }
}

.general {
  text-align: center;
}

.article {
  text-align: left;
  bottom: 50px;
  @media (max-width: $md) {
    bottom: 20px;
  }
}
.testimonial {
  text-align: left;
}

.order {
  color: $white;
  font-weight: $font-weight-700;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: $md) {
    font-size: 12px;
    line-height: 8px;
  }
}

.link {
  text-decoration: none;
  color: inherit;
}

.title {
  color: $white;
  margin-bottom: 24px;
  margin-top: 16px;
  h1 {
    font-weight: $font-weight-400;
    font-size: 29px;
    line-height: 44px;
  }

  @media (max-width: $md) {
    margin-bottom: 16px;
    h1 {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.publish {
  color: $white;
  font-weight: $font-weight-400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 88px;

  @media (max-width: $md) {
    font-size: 9px;
    line-height: 18px;
    margin-bottom: 10px;
  }
}

.content {
  text-align: left;
  font-weight: $font-weight-400;
  font-size: 20px;
  line-height: 24px;
  color: $white;
  margin-bottom: 88px;

  @media (max-width: $md) {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 20px;
  }
}

.general_image_title {
  text-align: left;
  font-weight: $font-weight-400;
  font-size: 30px;
  line-height: 44px;
  color: $white;
  margin-bottom: 24px;

  @media (max-width: $md) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;
  }
}

.quotes {
  color: $white;
  font-weight: $font-weight-400;
  font-size: 29px;
  line-height: 44px;
  margin-bottom: 24px;
  @media (max-width: $md) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  blockquote {
    margin: 24px auto;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }

    p {
      display: inline;
    }
  }
}

.btnContainer {
  text-align: left;
  :global {
    .MuiButton-containedSecondary {
      font-weight: $font-weight-500 !important;
      width: 204px;
      padding: 16px 12px;
      margin: 16px 0px;
      @media (max-width: $sm) {
        width: 80%;
        margin: 8px 0px;
        padding: 8px 6px;
      }
    }
  }
}

.button {
  max-width: 204px;
  width: 100%;
  height: 42px;
  border-radius: 4px;
  @media (max-width: $sm) {
    height: 32px;
  }
}
.linkText {
  color: $white;
  line-height: 16px;
  font-weight: $font-weight-500 !important;
  font-size: 18px !important;
  @media (max-width: $sm) {
    font-size: 14px !important;
  }
}
