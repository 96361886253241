@import 'styles/theme.scss';

.container {
}

.others {
  li {
    max-height: 590px;
  }
}

.articleSlides {
  li {
    max-height: 554px;
  }
}

.imageSlider {
  li {
    background-color: $white !important;
    position: relative;
  }
  .arrowStyle {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    color: $white;
    font-size: 60px;

    @media (max-width: $md) {
      margin-top: 15px;
      font-size: 30px;
    }
  }
}

.selected {
  opacity: 1;
  transform: scale(1.1);
}

.unselected {
  opacity: 0.6;
}

.containerMarginS {
  margin: $spacing * 6 0;

  @media (max-width: $md) {
    margin: $spacing * 2 0;
  }
}
