@import 'styles/theme.scss';

.title {
  margin-left: 24px;
  color: $primary-dark;
  @media (min-width: $sm) {
    max-width: 924px;
    margin: 12px auto 0;
  }
  h5 {
    font-weight: $font-weight-700;
    font-size: 24px;
    line-height: 34px;
    margin: 12px 0;
  }
}

.line {
  border: 1px solid $white;
  width: 54px;
  margin-left: 0;
}
.bgImage {
  position: relative;
}

.background {
  z-index: -1;
}

.Full_Width_BG {
  .title {
    color: $white;
  }
}

.One_Column {
  width: 100%;
  .title {
    max-width: 608px;
    margin: 32px auto;
  }
}

.One_Column_BG {
  align-items: center;
  .title {
    color: $white;
  }
}

.Two_Column {
  .title {
    margin: 24px auto;
  }
}

.Two_Column_BG {
  .title {
    color: $white;
  }
}

.Three_Column {
  .title {
    margin: 24px auto;
  }
}

.Three_Column_BG {
  .title {
    color: $white;
  }
}
