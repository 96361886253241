@import 'styles/theme.scss';

.white {
  color: $white !important;
}

.publish {
  color: $font-color;
  font-weight: $font-weight-400;
  font-size: 12px;
  line-height: 18px;
}

.date {
  white-space: nowrap;
}
