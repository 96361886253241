@import 'styles/theme.scss';

.container {
  text-align: left;
  line-height: 24px;
  margin: 0 auto !important;

  .title {
    h5 {
      font-size: 24px;
      line-height: 34px;
    }
  }

  .blurb {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: $md) {
    .title {
      h5 {
        font-size: 20px !important;
        line-height: 24px !important;
        margin-top: 0;
      }
    }

    .blurb {
      margin: 16px 0;
    }

    .info {
      max-height: 100% !important;
    }
  }
}

.containerBG {
  @media (max-width: $md) {
    width: 100% !important;
    padding: 28px !important;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.cover {
  position: relative;
  text-align: center;
  color: $secondary-light;
}

.image {
  width: 100%;
  height: 212px;
  object-fit: cover;
}

.topLeft {
  position: absolute;
  top: 12px;
  left: 14px;
  background-color: $category-light-blue;
  font-weight: $font-weight-500;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  padding: 4px 6px;
}

.link {
  text-decoration: none;
  color: inherit;
}

.blurb {
  color: $font-color;
  font-weight: $font-weight-400;
}

.title {
  color: $primary-dark;
  h5 {
    font-weight: $font-weight-700;
  }
}

.Full_Width {
  max-width: 924px;
  width: 100%;
  padding: 10px 0;

  .image {
    height: 212px;
    margin: 20px 0;
  }

  .info {
    max-height: 212px;
  }

  .blurb {
    color: $font-color;
  }

  .topLeft {
    top: 34px;
  }
}

.Full_Width_BG {
  max-width: 1022px;
  width: 100%;
  background-color: $white;
  padding: 16px 50px;
  border-radius: 14px;

  .image {
    height: 216px;
  }

  .info {
    max-height: 216px;
  }
  .cover {
    padding-top: 6px;
  }
}

.One_Column {
  max-width: 608px;
  width: 100%;
  padding: 10px 0;

  .image {
    height: 160px;
    margin: 20px 0;
  }

  .title {
    h5 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .blurb {
    font-size: 14px;
    line-height: 20px;
  }

  .info {
    max-height: 160px;
  }
  .topLeft {
    top: 30px;
  }
}

.One_Column_BG {
  max-width: 632px;
  width: 100%;
  background-color: $white;
  padding: 10px 24px;
  border-radius: 14px;

  .image {
    height: 160px;
  }
  .cover {
    padding-top: 6px;
  }
}

.Two_Column {
  .image {
    height: 220px;
  }

  .blurb {
    margin: 16px 0;
  }
}

.Two_Column_BG {
  max-width: 450px;
  width: 100%;
  background-color: $white;
  padding: 24px;
  border-radius: 14px;

  .image {
    height: 216px;
  }

  .info {
    padding-top: 8px;
  }

  .blurb {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.Three_Column {
  .image {
    height: 164px;
  }

  .title {
    h5 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .blurb {
    margin: 18px 0 24px 0;
  }
}

.Three_Column_BG {
  background-color: $white;
  .info {
    margin: 20px;
  }

  :global {
    .MuiGrid-spacing-xs-3 > .MuiGrid-root {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media (max-width: $md) {
    width: 100% !important;
    padding: 12px 0 !important;
  }
}
